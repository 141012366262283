import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";

interface DeleteCompetencyResponse {
    errors?: string;
    message?: string;
}
interface ViewCompetencyResponse {
    errors?: string;
    message?: string;
    competencies?: ViewCompetency;
}

interface ViewCompetency {
    data?: {
        id: string;
        attributes: {
            id: string;
            job_title: string;
            description: string;
            competency_type: string;
            file_filename: string;
            file_size: string;

        }
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    errorMsg: string;
    selectedLanguage: string;
    competencyId: string;
    selectedCompetencyType: string;
    viewCompetency: ViewCompetency | undefined;
    deleteCompetency: DeleteCompetencyResponse;
    openDeleteComp: boolean;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewCompetencyController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    viewCompetencyApiCallId: string = "";
    deleteCompetencyApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: "",
            errorMsg: "",
            selectedLanguage: "",
            competencyId: "",
            selectedCompetencyType: "",
            viewCompetency: {},
            deleteCompetency: {},
            openDeleteComp: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.viewCompetencyApiCallId) {
                this.viewCompetencyApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteCompetencyApiCallId) {
                this.deleteCompetencyApiResponse()
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })

        const competencyId = await getStorageData("competencyId");
        const parsedId = await JSON.parse(competencyId)
        this.setState({ competencyId: parsedId }, () => {
            this.viewCompetencyApiCall()
        });
    }

    viewCompetencyApiResponse = (responseJson: ViewCompetencyResponse) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                viewCompetency: responseJson.competencies
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
            });
        }
    }

    deleteCompetencyApiResponse = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    viewCompetencyApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.viewCompetencyApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.viewCompetencyApiEndPoint}/${this.state.competencyId}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    deleteCompetencyApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteCompetencyApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteCompetencyApiEndPoint}/${this.state.competencyId}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    handleCloseDeleteComp = () => {
        this.setState({openDeleteComp: false})
    }

    navigateToDashboard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    EditJobCompetency = async () => {
        await setStorageData('competencyId', JSON.stringify(this.state.competencyId));
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Cfchatgptcompetencymapgeneration");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    deleteCompetency = () => {
         this.setState({ openDeleteComp: true})
    }
    // Customizable Area End

}