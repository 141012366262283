import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { setStorageData, getStorageData } from "framework/src/Utilities";
interface ShowDicResponse {
    message: string;
    error: string;
    competency_dictionary: ShowDictionaryData;
}

interface ShowDictionaryData {
    data?: {
        id: string;
        attributes: {
            id: string;
            competency_name: string;
            description: string;
            created_at: string;
            job_level: {
                id: string;
                name: string;
            },
            competency_type: {
                id: string;
                name: string;
            }
        }
    }
}
interface CreateDictionaryResponse {
    message: string;
    error: string;
    competency_dictionary: CreateDictionaryData;
}

interface CreateDictionaryData {
    data?: {
        id: string;
        type: string;
        attributes: {
            id: string;
            competency_name: string;
            description: string;
            created_at: string;
            job_level: {
                id: string;
                name: string;
            },
            competency_type: {
                id: string;
                name: string;
            }
        }
    }
}
interface CompetencyTypeResponse {
    message: string;
    error: string;
    competency_types: CompetencyData[];
}
interface CompetencyData {
    id?: string;
    name?: string;
}

interface TargetLevelResponse {
    id: string;
    name: string;
}
interface TargetData {
    id?: string;
    name?: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    errorMsg: string;
    competencyName: string;
    selectedCompetencyType: string;
    competencyType: CompetencyData[];
    selectedTargetLevel: string;
    targetLevel: TargetData[];
    dictionaryDescription: string;
    saveDicClicked: boolean;
    createDictionary: CreateDictionaryData;
    updateDictionary: CreateDictionaryData;
    openDicDialog: boolean;
    isEdit: boolean;
    dictionaryId: string;
    showDictionaryData: ShowDictionaryData;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class DictionaryCreationController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    competencyTypeApiCallId: string = "";
    targetLevelApiCallId: string = "";
    createDictionaryApiCallId: string = "";
    viewDictionaryApiCallId: string = "";
    updateDictionaryApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            competencyName: "",
            selectedCompetencyType: "",
            competencyType: [],
            selectedTargetLevel: "",
            targetLevel: [],
            dictionaryDescription: "",
            saveDicClicked: false,
            createDictionary: {},
            updateDictionary: {},
            openDicDialog: false,
            isEdit: false,
            dictionaryId: "",
            showDictionaryData: {}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.competencyTypeApiCallId) {
                this.handleCompetencyResponse(responseJson)
            }
            if (apiRequestCallId === this.targetLevelApiCallId) {
                this.handleTargetLevelResponse(responseJson)
            }
            if (apiRequestCallId === this.createDictionaryApiCallId) {
                this.createDictionaryResponse(responseJson)
            }
            if (apiRequestCallId === this.viewDictionaryApiCallId) {
                this.viewDictionaryResponse(responseJson)
            }
            if (apiRequestCallId === this.updateDictionaryApiCallId) {
                this.updateDictionaryResponse(responseJson)
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = JSON.parse(signInResponse)
        this.setState({
            token: parsedSignInResponse.meta?.token,
        })

        const dictionaryId = await getStorageData("dictionaryId");
        if (dictionaryId) {
            this.setState({ dictionaryId: dictionaryId, isEdit: true }, () => {
                this.viewDictionaryApiCall();
            })
        }
        this.competencyTypeApiCall();
        this.targetLevelApiCall();
    }

    handleCompetencyResponse = (responseJson: CompetencyTypeResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                competencyType: responseJson.competency_types
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleTargetLevelResponse = (responseJson: TargetLevelResponse[]) => {
        this.setState({
            targetLevel: responseJson
        })
    }

    createDictionaryResponse = (responseJson: CreateDictionaryResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                createDictionary: responseJson.competency_dictionary,
                openDicDialog: true
            }, () => {
                setStorageData("dictionaryId", this.state.createDictionary.data?.id);
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    updateDictionaryResponse = (responseJson: CreateDictionaryResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                updateDictionary: responseJson.competency_dictionary,
                openDicDialog: true
            })
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    viewDictionaryResponse = (responseJson: ShowDicResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                showDictionaryData: responseJson.competency_dictionary
            }, () => {
                this.setState({
                    competencyName: this.state.showDictionaryData?.data?.attributes.competency_name || "",
                    selectedCompetencyType: this.state.showDictionaryData?.data?.attributes.competency_type.id || "",
                    selectedTargetLevel: this.state.showDictionaryData?.data?.attributes.job_level.id || "",
                    dictionaryDescription: this.state.showDictionaryData?.data?.attributes.description || ""
                })
            });
        } else if (responseJson && responseJson.error) {
            this.setState({
                errorMsg: responseJson.error,
            });
        }
    }

    handleSaveCompDictionary = () => {
        this.setState({ saveDicClicked: true })
        const errorInCompName = !this.state.competencyName;
        const errorInCompType = !this.state.selectedCompetencyType;
        const errorInTargetLevel = !this.state.selectedTargetLevel;
        const errorInDiscription = !this.state.dictionaryDescription;

        if (errorInCompName ||
            errorInCompType ||
            errorInTargetLevel ||
            errorInDiscription
        ) {
            return;
        }
        else {
            if (this.state.isEdit) {
                this.updateDictionaryApiCall();
            } else {
                this.createDictionaryApiCall();
            }
        }
    }

    viewDictionaryApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.viewDictionaryApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${this.state.dictionaryId}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    createDictionaryApiCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };

        let body = JSON.stringify({
            "competency": {
                "competency_name": this.state.competencyName,
                "description": this.state.dictionaryDescription,
                "competency_type_id": this.state.selectedCompetencyType,
                "job_level_id": this.state.selectedTargetLevel
            }
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createDictionaryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateDictionaryApiCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json"
        };

        let body = JSON.stringify({
            "competency": {
                "competency_name": this.state.competencyName,
                "description": this.state.dictionaryDescription,
                "competency_type_id": this.state.selectedCompetencyType,
                "job_level_id": this.state.selectedTargetLevel
            }
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDictionaryApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dictionaryApiEndPoint}/${this.state.dictionaryId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    competencyTypeApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.competencyTypeApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCompetencyApiEndPoint}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    targetLevelApiCall = () => {
        const headers = {
            "token": this.state.token
        };
        const getCompetencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.targetLevelApiCallId = getCompetencyMsg.messageId;
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.targetLevelEndPoint}`
        );
        getCompetencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getCompetencyMsg.id, getCompetencyMsg);
    }

    closeDicDialog = () => {
        this.setState({ openDicDialog: false })
    }

    handleCompetencyNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ competencyName: event.target.value })
    }

    handleCompetencyTypeChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedCompetencyType: event.target.value });
    }

    handleTargetLevelChange = (event: SelectChangeEvent<string>) => {
        this.setState({ selectedTargetLevel: event.target.value });
    }

    handleDicDescription = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ dictionaryDescription: event.target.value.length > 1000 ? this.state.dictionaryDescription : event.target.value })
    }

    navigateToDashboard = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "CompetencyDictionaryDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    navigateToViewDictionary = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewDictionary");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    // Customizable Area End
}