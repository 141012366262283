Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfchatgptcompetencymapgeneration";
exports.labelBodyText = "cfchatgptcompetencymapgeneration Body";

exports.btnExampleTitle = "CLICK ME";

exports.errorText = "This field is required";
exports.fileErrorText = "You can upload word and pdf files up to 5 mb's.";
exports.uploadJdText = "Upload your JD document";
exports.uploadJdSizeText = "You can upload word and pdf files up to 5 MB's";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.patchApiMethodType = "PATCH";
exports.deleteApiMethodType = "DELETE";
exports.getCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies/competency_types";
exports.createCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.updateCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.viewCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.deleteCompetencyApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies";
exports.multiDeleteApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competencies/bulk_destroy";
exports.targetLevelEndPoint = "bx_block_dashboardguests/job_descriptions/job_level_list";
exports.dictionaryApiEndPoint = "bx_block_cfchatgptcompetencymapgeneration/competency_dictionaries";
// Customizable Area End