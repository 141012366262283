import React,{ReactNode} from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField, IconButton, InputAdornment, Grid,InputLabel,Modal
  } from "@mui/material";
import {signInImage, signInLogo } from "../../email-account-login/src/assets";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomPopup from "../../../components/src/CustomSuccessModel.web";
import { withTranslation } from "react-i18next";
import ForgotPasswordController, {
  Props,
  configJSON
} from "./ForgotPasswordController";
interface BoxProps {
    children: ReactNode;
  }
  
  const InnerBox: React.FC<BoxProps> = ({ children }) => (
    <Box
      sx={{
        position: 'absolute',
        top: '79%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px',
      }}
    >
      {children}
    </Box>
  );
   const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      borderRadius: '8px',
    },
    '& .MuiInputBase-input': {
      color: '#0F172A',
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.08px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#94A3B8',
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.08px',
    },
    '& .MuiInputBase-input:hover': {
      borderColor: '#1976d2',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginTop: '4px',
    },
  };
  
  const formBoxStyles = {
    '& > :not(style)': {
      width: '25ch',
      marginTop: '0.25rem',
    },
  };
  
  const FormBox: React.FC<BoxProps> = ({ children }) => (
    <Box component="form" sx={formBoxStyles}>
      {children}
    </Box>
  );

const resetStyle = {
    resetDescription: {
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '26px',
      color: '#475569',
      margin: '12px 0',
  
    },
    resetLabel: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#334155',
      letterSpacing: '0.08px',
    },
   resetBtnTitle: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '26px',
      color: '#FFFFFF',
      letterSpacing: '0.12px', 
      textTransform: "capitalize"
    },
  
  
  
    resetTextTitle: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: '44px',
      letterSpacing: '-0.5%',
      color: '#FFFFFF',
    },
  
    resetTextDescription: {
      
      fontFamily: 'Urbanist',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '26px',
      margin: '12px 0',
      color: '#ffffff',
      width: "100%"
    },
  
  
  
    resetInTitle: {
      fontFamily: 'Urbanist',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '-0.5%',
      color: '#000000',
    },
  
    imageBoxStyle: {
      position: 'relative',
      color: 'white',
      textAlign: 'center',
      height: '100vh',
      font: 'poppins'
    },
    imageStyle: {
      width: "110px",
      height: "110px",
      transform: 'translateX(-10px)',
    }
  
  };

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderMessage() {

    return (
      <>
        <Box sx={{marginBottom:"40px"}}>
        <Typography style={resetStyle.resetInTitle}>{this.props.i18n?.t("register.wellDone")}</Typography>
        <Typography style={resetStyle.resetDescription}>{this.props.i18n?.t("register.congratulations")}</Typography>
        </Box>
      </>
    );
 
}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      <Grid container  sx={{direction:`${this.state.selectedLanguage.toLowerCase() === "english"
           ? "ltr" : "rtl"}`}}>
            <Grid item md={6} xs={12} sm={12}>
                <Box style={{ marginBottom:"20px", marginTop: '94px', marginLeft:"60px" }}>

                  <Grid container justifyContent="center" alignItems="center" >
                  <Grid
                    item md={9}
                    sm={11}
                    xs={11}>

                      <Box style={{ padding: '1rem', paddingBottom:"50px" }} onClick={this.handleNavigateLanding}>
                        <img style={resetStyle.imageStyle} src={signInLogo.default} alt="HrTech" />
                      </Box>
                      {this.renderMessage()}

                        <InputLabel sx={{...resetStyle.resetLabel, 
                        direction: `${this.state.selectedLanguage.toLocaleLowerCase() === "english" ? "ltr" : "rtl"}`,
                        marginBottom:"0.25rem"
                        }} htmlFor="my-input">
                        {this.props.i18n?.t("register.newPassword")}
                        </InputLabel>
                        <TextField
                          data-test-id="password"
                          required
                          type={this.state.showPassword ? 'text' : 'password'}
                          name="password"
                          value={this.state.newPasswords.password}
                          style={{ width: "100%" }}
                          onChange={this.handleChangeResetPassword}
                          placeholder={this.props.i18n?.t("register.yourPassword")}
                          error={!!this.state.passwordError}
                          helperText={this.state.passwordError}
                          sx={textFieldStyles}
                          FormHelperTextProps={{
                            sx: {
                              fontFamily: 'Urbanist',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '18px',
                              color: '#DC2626',
                              marginLeft: 0,
                              marginTop: '8px',
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <InputLabel sx={{...resetStyle.resetLabel, 
                        direction: `${this.state.selectedLanguage.toLocaleLowerCase() === "english" ? "ltr" : "rtl"}`,
                        marginY:"0.25rem",
                        
                        }} htmlFor="my-input">
                        {this.props.i18n?.t("register.confirmPassword")}
                        </InputLabel>
                        <TextField
                          data-test-id="password"
                          required
                          type={this.state.showPasswordConfirm ? 'text' : 'password'}
                          name="confirmPassword"
                          value={this.state.newPasswords.confirmPassword}
                          style={{ width: "100%" }}
                          onChange={this.handleChangeResetPassword}
                          placeholder={this.props.i18n?.t("register.yourConfirmedPassword")}
                          helperText={this.state.commonMesage|| this.state.confirmPasswordError}
                          error={!!this.state.confirmPasswordError}
                          sx={textFieldStyles}
                          FormHelperTextProps={{
                            sx: {
                              fontFamily: 'Urbanist',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '18px',
                              color: '#DC2626',
                              marginLeft: 0,
                              marginTop: '8px',
                              textAlign: `${this.state.selectedLanguage.toLocaleLowerCase() === "english" ? "left" : "right"}`,
                              direction: `${this.state.selectedLanguage.toLocaleLowerCase() === "english" ? "ltr" : "rtl"}`
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPasswordConfirm}
                                  edge="end"
                                  
                                >
                                  {this.state.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      <Box>
                        <Grid container>
                          <Grid item md={9} lg={9} sm={12} xs={12}>
                            <Box sx={{
                              padding: "0.5rem", marginTop: '3rem', background: '#044352'
                            }}>
                              <Button fullWidth
                                onClick={this.resetPasswordButton}
                                data-test-id="signUpBtn">
                                <Typography sx={resetStyle.resetBtnTitle}>{this.props.i18n?.t("register.submit")}</Typography>
                              </Button>

                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
            </Grid>
            <Modal      
              open={this.state.ChangedOpen}
              onClose={this.closeChangedModal}>
              <CustomPopup 
              data-test-id="custom-popup-changed"
              headingPopup={this.props.i18n?.t("register.changedheadingPopup")} 
              titlePopup={this.props.i18n?.t("register.successTitleChanged")} 
              subTitlePopup = {this.props.i18n?.t("register.subTitleChangedPopup")}
              buttonText={this.props.i18n?.t("register.buttonTextChanged")}
              onOpen={this.openChangedModal}
              onClose={this.closeChangedModal}/>
            </Modal>
            <Grid item md={6} xs={12} sm={12} sx={{ padding: 0 }}>
            <Box
              sx={{
                position: 'relative',
                color: 'white',
                textAlign: 'center',
                fontFamily: 'poppins',
                height: '100vh',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <img
                src={signInImage.default}
                alt="Weddng__W"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '0px 0px 0px 64px',
                  filter: 'brightness(100%)',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '79%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '10px',
                  width: '100%',
                }}
              >
                <Typography sx={resetStyle.resetTextTitle}>
                {this.props.i18n?.t("register.revolutionizeAI")}
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <Typography sx={resetStyle.resetTextDescription}>
                  {this.props.i18n?.t("register.talentManagement")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
            <ToastContainer />
          </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ForgotPasswordWeb = withTranslation()(ForgotPassword)
// Customizable Area End
