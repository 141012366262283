import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "framework/src/Utilities";

interface Industry {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface IndustryType {
  message?: string;
  industries: Industry[];
  error?: string;
}

interface Country {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CountryData {
  message?: string;
  countries?: Country[];
  error?: string;
}

interface CountryNameAndRegion {
  key: string;
  country_name: string;
  country_code: string;
  region: string;
}

interface ContryResponse {
  message?: string;
  error?: string;
  data?: CountryNameAndRegion[];
}
interface CompanySizeData {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CompanySize {
  message?: string;
  sizes: CompanySizeData[];
  error?: string;
}

interface CompanyStageData {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CompanyStage {
  message?: string;
  stages: CompanyStageData[];
  error?: string;
}

interface JdStyleData {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface JdStyle {
  message?: string;
  job_styles: JdStyleData[];
  error?: string;
}

interface CreateCompany {
  message?: string;
  error?: string;
  organisation_details?: {
    data?: {
      id: string;
      type: string;
      attributes: {
        company_name: string;
        company_culture: string;
        industry_name: string;
        company_size_name: string;
        stage_name: string;
        organizational_keywords: string;
        job_description_style_name: string;
        document_url: string;
        document_filename: string;
        document_size: string;
      }
    }
  }
}

interface UpdateCompany {
  message?: string;
  error?: string;
  organisation_details?: {
    data?: {
      id: string;
      type: string;
      attributes: {
        company_name: string;
        company_culture: string;
        industry_name: string;
        company_size_name: string;
        stage_name: string;
        organizational_keywords: string;
        job_description_style_name: string;
        document_url: string;
        document_filename: string;
        document_size: string;
      }
    }
  }
}
interface ShowCompanyData {
  error?: string;
  message?: string;
  organisation_details?: {
    data?: {
      id: string;
      type: string;
      attributes: {
        company_culture: string;
        industry_id: string;
        industry_name: string;
        company_size_id: string;
        company_size_name: string;
        stage_id: string;
        stage_name: string;
        organizational_keywords: string;
        job_description_style_id: string;
        job_description_style_name: string;
        document_url: string;
        document_filename: string;
        document_size: string;
        company_name: string;
        country: string;
        region: string;
      }
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  errorMsg: string;
  selectedIndustryType?: string;
  country: ContryResponse;
  selectedCountry?: string;
  selectedRegion?: string;
  industryType: IndustryType;
  selectedCompanySize?: string;
  companySize: CompanySize;
  selectedCompanyStage?: string;
  companyStage: CompanyStage;
  selectedJdStyle?: string;
  jdStyle: JdStyle;
  companyCulture?: string;
  organizationKeywords: string;
  chipData: string[] | undefined;
  uploadedFile: string | undefined;
  selectedFile: File | null;
  selectedFileBase64: string;
  fileName: string | undefined;
  fileSize: string | undefined;
  fileError: string;
  submitClicked: boolean;
  createCompany: CreateCompany;
  updateCompany: UpdateCompany;
  openCompanySuccess: boolean;
  selectedLanguage: string;
  showCompanyData: ShowCompanyData;
  isEdit: boolean;
  organisationName?: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  countryApiCallId: string = "";
  industryTypeApiCallId: string = "";
  companySizeApiCallId: string = "";
  companyStageApiCallId: string = "";
  jdStyleApiCallId: string = "";
  createCompanyDetailsApiCallId: string = "";
  updateCompanyDetailsApiCallId: string = "";
  showCompanyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      selectedIndustryType: "",
      country: {},
      selectedCountry: "",
      selectedRegion: "",
      industryType: { industries: [] },
      selectedCompanySize: "",
      companySize: { sizes: [] },
      selectedCompanyStage: "",
      companyStage: { stages: [] },
      selectedJdStyle: "",
      jdStyle: { job_styles: [] },
      companyCulture: "",
      organizationKeywords: "",
      chipData: [],
      uploadedFile: "",
      selectedFile: null,
      selectedFileBase64: "",
      fileName: "",
      fileSize: "",
      fileError: "",
      submitClicked: false,
      token: "",
      errorMsg: "",
      createCompany: {},
      updateCompany: {},
      openCompanySuccess: false,
      selectedLanguage: "",
      showCompanyData: {},
      isEdit: false,
      organisationName: ""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.allListingApiCall(apiRequestCallId, responseJson)
      if (apiRequestCallId === this.industryTypeApiCallId) {
        this.handleIndustryTypeApiResponse(responseJson)
      }
      if (apiRequestCallId === this.companySizeApiCallId) {
        this.handleCompanySizeApiResponse(responseJson)
      }
      if (apiRequestCallId === this.companyStageApiCallId) {
        this.handleCompanyStageApiResponse(responseJson)
      }
      if (apiRequestCallId === this.jdStyleApiCallId) {
        this.handleJdStyleApiResponse(responseJson)
      }
      if (apiRequestCallId === this.createCompanyDetailsApiCallId) {
        this.handleCreateCompanyApiResponse(responseJson)
      }
      if (apiRequestCallId === this.updateCompanyDetailsApiCallId) {
        this.handleUpdateCompanyApiResponse(responseJson)
      }
      if (apiRequestCallId === this.showCompanyApiCallId) {
        this.handleShowCompanyApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const language = await getStorageData("language") || "English";
    this.setState({ selectedLanguage: language });

    const signInResponse = await getStorageData("signInResponse");
    const parsedSignInResponse = JSON.parse(signInResponse)
    this.setState({
      token: parsedSignInResponse.meta?.token,
    })
    this.getCountryApiCall();
    this.getIndustryTypeApiCall();
    this.getCompanySizeApiCall();
    this.getCompanyStageApiCall();
    this.getjdStyleApiCall();
    this.showCompanyApiCall();
  }

  allListingApiCall = (apiRequestCallId: string, responseJson: ContryResponse) => {
    if (apiRequestCallId === this.countryApiCallId) {
      this.handleCountryApiResponse(responseJson)
    }
  }

  handleCloseCompanySuccess = () => {
    this.setState({ openCompanySuccess: false })
  }

  handleCountryApiResponse = (responseJson: ContryResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ country: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleIndustryTypeApiResponse = (responseJson: IndustryType) => {
    if (responseJson && !responseJson.error) {
      this.setState({ industryType: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleCompanySizeApiResponse = (responseJson: CompanySize) => {
    if (responseJson && !responseJson.error) {
      this.setState({ companySize: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleCompanyStageApiResponse = (responseJson: CompanyStage) => {
    if (responseJson && !responseJson.error) {
      this.setState({ companyStage: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleJdStyleApiResponse = (responseJson: JdStyle) => {
    if (responseJson && !responseJson.error) {
      this.setState({ jdStyle: responseJson })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleCreateCompanyApiResponse = (responseJson: CreateCompany) => {
    if (responseJson && !responseJson.error) {
      this.setState({ createCompany: responseJson, openCompanySuccess: true })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleUpdateCompanyApiResponse = (responseJson: UpdateCompany) => {
    if (responseJson && !responseJson.error) {
      this.setState({ updateCompany: responseJson, openCompanySuccess: true })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleShowCompanyApiResponse = (responseJson: ShowCompanyData) => {
    if (responseJson && !responseJson.error) {
      this.setState({ showCompanyData: responseJson }, () => {
        const { showCompanyData } = this.state;

        if (showCompanyData?.organisation_details?.data) {
          const attributes = showCompanyData.organisation_details.data.attributes;
          this.setState({
            isEdit: showCompanyData.organisation_details.data.id ? showCompanyData.organisation_details.data.id.length > 0 : false,
            organisationName: attributes?.company_name,
            selectedCountry: attributes?.country,
            selectedRegion: attributes?.region,
            companyCulture: attributes?.company_culture,
            selectedIndustryType: attributes?.industry_id,
            selectedCompanySize: attributes?.company_size_id,
            selectedCompanyStage: attributes?.stage_id,
            selectedJdStyle: attributes?.job_description_style_id,

            chipData: attributes?.organizational_keywords.split(", "),
            uploadedFile: attributes?.document_url,
            fileName: attributes?.document_filename,
            fileSize: attributes?.document_size
          })
        }
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  organisationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ organisationName: event.target.value })
  }

  handleCopanyCulture = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ companyCulture: event.target.value.length > 1000 ? this.state.companyCulture : event.target.value })
  }

  handleIndustryType = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedIndustryType: event.target.value });
  }

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCountry: event.target.value }, () => {
      const region = this.state.country.data?.filter((item) => {
        return item.country_name === this.state.selectedCountry
      })
      if (region?.length) {
        this.setState({ selectedRegion: region[0].region })
      }
    });
  }

  handleCompanySize = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCompanySize: event.target.value })
  }

  handleCompanyStage = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedCompanyStage: event.target.value })
  }

  handleJdStyle = (event: SelectChangeEvent<string>) => {
    this.setState({ selectedJdStyle: event.target.value })
  }

  handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ organizationKeywords: event.target.value });
  };

  handleAddChip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.state.organizationKeywords.trim()) {
      this.setState((prevState) => ({
        chipData: [...prevState.chipData || [], prevState.organizationKeywords],
        organizationKeywords: ""
      }))
    }
  };

  handleDeleteChip = (chipToDelete: string) => () => {
    this.setState((prevState) => ({
      chipData: (prevState.chipData || []).filter((chip) => chip !== chipToDelete),
    }));
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      const maxSizeInMB = 5;
      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(selectedFile.type) || (selectedFile.size > maxSizeInMB * 1024 * 1024)) {
        this.setState({ fileError: configJSON.fileErrorText })
        return;
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;
          this.setState({ selectedFile, selectedFileBase64: base64String, fileError: "" });
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  handleImageClick = () => {
    const fileInput = document.getElementById('upload-jd-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  handleFileDelete = () => {
    this.setState({ selectedFile: null, uploadedFile: "", fileName: "", fileSize: "", fileError: "" })
  }

  isEditJdTemplate = () => {
    let displayText = configJSON.uploadJdText;
    if (this.state.selectedFile) {
      displayText = this.state.selectedFile.name;
    } else if (this.state.fileName) {
      displayText = this.state.fileName;
    }

    return displayText;
  }

  isEditJdDocumentSize = () => {
    let sizeText = configJSON.uploadJdSizeText;

    if (this.state.selectedFile) {
      sizeText = `${(this.state.selectedFile.size / 1024).toFixed(2)} KB`;
    } else if (this.state.fileSize) {
      sizeText = `${this.state.fileSize} KB`;
    }
    return sizeText
  }

  getCountryApiCall = () => {
    let lang = this.state.selectedLanguage === "English" ?
      this.state.selectedLanguage.toLowerCase() : "ar"

    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryCodeApiEndPoint}?language=${lang}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  getIndustryTypeApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.industryTypeApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.industryTypeApiEndPoint}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  getCompanySizeApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.companySizeApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companySizeApiEndPoint}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  getCompanyStageApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.companyStageApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyStageApiEndPoint}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  getjdStyleApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jdStyleApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.jdStyleApiEndPoint}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }
  navigateToViewCompany = () => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ViewCompanyDetails");
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }

  createCompanyDetailApiCall = () => {
    const headers = {
      token: this.state.token,
    };

    const formdata = new FormData();
    if (this.state.selectedCompanySize) {
      formdata.append("organisation_details[company_size_id]", this.state.selectedCompanySize);
    }
    if (this.state.organisationName) {
      formdata.append("organisation_details[company_name]", this.state.organisationName);
    }
    if (this.state.companyCulture) {
      formdata.append("organisation_details[company_culture]", this.state.companyCulture);
    }
    if (this.state.selectedIndustryType) {
      formdata.append("organisation_details[industry_id]", this.state.selectedIndustryType);
    }
    if (this.state.selectedCompanyStage) {
      formdata.append("organisation_details[stage_id]", this.state.selectedCompanyStage);
    }
    if (this.state.chipData) {
      formdata.append("organisation_details[organizational_keywords]", this.state.chipData?.join(", "));
    }
    if (this.state.selectedCountry) {
      formdata.append("organisation_details[country]", this.state.selectedCountry);
    }
    if (this.state.selectedJdStyle) {
      formdata.append("organisation_details[job_description_style_id]", this.state.selectedJdStyle);
    }
    if (this.state.selectedRegion) {
      formdata.append("organisation_details[region]", this.state.selectedRegion);
    }
    if (this.state.selectedFile) {
      formdata.append("organisation_details[document]", this.state.selectedFile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCompanyDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createCompanyApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateCompanyDetailApiCall = () => {
    const headers = {
      token: this.state.token,
    };

    const formdata = new FormData();
    if (this.state.selectedJdStyle) {
      formdata.append("organisation_details[job_description_style_id]", this.state.selectedJdStyle);
    }
    if (this.state.organisationName) {
      formdata.append("organisation_details[company_name]", this.state.organisationName);
    }
    if (this.state.companyCulture) {
      formdata.append("organisation_details[company_culture]", this.state.companyCulture);
    }
    if (this.state.selectedIndustryType) {
      formdata.append("organisation_details[industry_id]", this.state.selectedIndustryType);
    }
    if (this.state.selectedCompanySize) {
      formdata.append("organisation_details[company_size_id]", this.state.selectedCompanySize);
    }
    if (this.state.selectedCompanyStage) {
      formdata.append("organisation_details[stage_id]", this.state.selectedCompanyStage);
    }
    if (this.state.chipData) {
      formdata.append("organisation_details[organizational_keywords]", this.state.chipData?.join(", "));
    }
    if (this.state.selectedCountry) {
      formdata.append("organisation_details[country]", this.state.selectedCountry);
    }
    if (this.state.selectedRegion) {
      formdata.append("organisation_details[region]", this.state.selectedRegion);
    }
    if (this.state.selectedFile) {
      formdata.append("organisation_details[document]", this.state.selectedFile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCompanyApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showCompanyApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    const getMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showCompanyApiCallId = getMsg.messageId;
    getMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showCompanyApiEndPoint}`
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMsg.id, getMsg);
  }

  handleCompanyDetailSubmit = () => {
    this.setState({ submitClicked: true })
    const errorInOrganisationName = !this.state.organisationName;
    const errorInCountry = !this.state.selectedCountry;
    const errorInRegion = !this.state.selectedRegion;
    const errorInCompanyCulture = !this.state.companyCulture;
    const errorInIndustryType = !this.state.selectedIndustryType;
    const errorInCompanySize = !this.state.selectedCompanySize;
    const errorInCompanyStage = !this.state.selectedCompanyStage;
    const errorInJdstyle = !this.state.selectedJdStyle;
    const errorInKeywords = (this.state.chipData || []).length < 1;
    const errorInFile = (!this.state.isEdit && !this.state.selectedFile) ||
      (this.state.isEdit && ((this.state.uploadedFile || "").length < 1 && !this.state.selectedFile))

    if (errorInOrganisationName ||
      errorInCountry ||
      errorInRegion ||
      errorInCompanyCulture ||
      errorInIndustryType ||
      errorInCompanySize ||
      errorInCompanyStage ||
      errorInJdstyle ||
      errorInKeywords ||
      errorInFile) {
      return
    }

    if (this.state.isEdit) {
      this.updateCompanyDetailApiCall();
    } else {
      this.createCompanyDetailApiCall();
    }
  }
  // Customizable Area End

}
