import React from "react";
// Customizable Area Start
import { Box, styled, Typography, Grid } from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader} from "../../dashboard/src/DashboardHeader.web";
import { emptyDashboardImg } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <DashboardHeader selectedMenu="Dashboard" navigation={this.props.navigation} id={this.props.id}>
        <MainWrapper>
          <Grid container className="gridContainer">
            <Grid item xs={12}>
              <Box className="imageBox">
                <img src={emptyDashboardImg.default} />
                <Typography className="emptyMainText">
                  {this.props.i18n?.t("dashboard.noData")}
                </Typography>
                <Typography className="emptyNextText">
                  {this.props.i18n?.t("dashboard.noDataSubText")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </MainWrapper>
      </DashboardHeader>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const DashboardWeb = withTranslation()(Dashboard)
export const MainWrapper = styled(Box)({
  width: "100%",
  height: "calc(100vh - 180px)",
  paddingBottom: "30px",
  "& .arabicDirection": {
    direction: "rtl",
  },
  "& .emptyMainText": {
    fontFamily: "Urbanist",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#000104"
  },
  "& .emptyNextText": {
    fontFamily: "Urbanist",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#8A96A8",
    width: "50%",
    textAlign: "center"
  },
  "& .imageBox": {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .gridContainer": {
    height: "100%"
  }
});
// Customizable Area End
