import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    InputBase,
    MenuItem,
    Menu,
    CardContent,
    Card,
    Checkbox,
    Pagination,
    CircularProgress,
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { emptyDashboardImg, calendar, selectCompArrow, dropdownImg, deleteComp } from "./assets";
import Calendar from "react-calendar";
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Customizable Area End

import CompetencyDashboardController, {
    Props,
} from "./CompetencyDashboardController";

export default class CompetencyDashboard extends CompetencyDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noDataScreen = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="compNoDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="compNoDataNextText">
                                Competencies help you identify the skills and behaviors necessary for different job roles. Start by creating a new competency that aligns with your organization's goals. Click 'Create Competency' to get started.
                            </Typography>
                            <Button
                                className="createCompetencyButton"
                                data-test-id="create_competency"
                                onClick={this.navigateToCreateComp}>
                                <Typography className="compCreateText">
                                    Create Competency
                                </Typography>
                            </Button>
                            <Button
                                className="createCompDictionaryButton"
                                data-test-id="create_competency_dictionary"
                                onClick={this.navigateToDictionary}
                            >
                                <Typography className="compDectionaryText">
                                    Create Competency Dictionary
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    filterAndSearchNodata = () => {
        return (
            <EmptyWrapper>
                <Grid container className="gridContainer">
                    <Grid item xs={12}>
                        <Box className="compNoDataImageBox">
                            <img src={emptyDashboardImg.default} />
                            <Typography className="compNoDataMainText">Ohh No, No data Found</Typography>
                            <Typography className="noDataNextText">It looks like there’s nothing here yet. Add New User for greater efficiency and growth.</Typography>

                        </Box>
                    </Grid>
                </Grid>
            </EmptyWrapper>
        )
    }

    multiDeleteCompetencyDialog = () => {
        return (
            <Dialog
                onClose={this.handleCloseDeleteComp}
                open={this.state.openMultiDelete}
                PaperProps={{
                    sx: {
                        borderRadius: "8px 8px 32px 8px",
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl",
                        overflow: 'hidden',
                    }
                }}
            >
                <DialogTitle>
                    <Wrapper>
                        <Typography className="deleteCompDialogTitle">
                            Delete Details
                        </Typography>
                        <IconButton
                            data-test-id="close_delete_competency"
                            aria-label="close"
                            onClick={this.handleCloseDeleteComp}
                            className="multiDeleteDialogClose"
                            sx={{
                                left: this.state.selectedLanguage.toLowerCase() === "english" ? "auto" : 30,
                                right: this.state.selectedLanguage.toLowerCase() === "english" ? 30 : "auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Wrapper>
                </DialogTitle>
                <Divider sx={{ color: "#E2E8F0" }} />
                <DialogContent>
                    <Wrapper>
                        <Box className="multiDeleteImageBox">
                            <img src={deleteComp.default} alt="Success Image" height={144} width={144} />
                        </Box>
                    </Wrapper>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "20px",
                            fontFamily: "Urbanist",
                            padding: {
                                xs: "14px 14px",
                                sm: "14px 64px"
                            },
                            lineHeight: "28px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        Are you sure you want to delete this Job competencies?
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Urbanist",
                            fontWeight: 400,
                            lineHeight: "26px",
                            padding: "0px 68px",
                            fontSize: "18px",
                            color: "#0F172A",
                            textAlign: "center"
                        }}>
                        You won't be able to access them again.
                    </Typography>

                </DialogContent>
                <Divider />
                <DialogActions>
                    <Wrapper>
                        <Box className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "multiDeleteButtonsBox" : "multiDeleteButtonsArabic"}`}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleCloseDeleteComp}
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "cancelMultiDeleteButton" : "cancelMultiDeleteArabic"}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.state.selectedRowIds.length ? this.multiDeleteApiCall : () => this.handleDelete(this.state.singleDeleteId)}
                                data-test-id="delete_comp"
                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "multiDeleteCompButton" : "multiDeleteButtonArabic"}`}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Wrapper>
                </DialogActions>
            </Dialog>
        )
    }

    selectDate = () => {
        return (
            <Grid item xs={12} md={6} xl={6}>
                <CustomDatePicker sx={{ flex: 1 }}>
                    <TextField
                        fullWidth
                        value={this.formatDateRange(this.state.selectedDate) || 'Select Start Date - End Date'}
                        onClick={this.openCalendar}
                        data-test-id="calendar_field"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={this.openCalendar}>
                                        <img src={calendar.default} onClick={this.openCalendar} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            inputProps: {
                                sx: {
                                    color: this.handleCondition(this.state.selectedDate, '#334155', '#6C7B89'),
                                    fontFamily: "Urbanist",
                                    fontWeight: this.handleCondition(this.state.selectedDate, 700, 400),
                                    fontSize: "16px",
                                    paddingRight: '0px',
                                    paddingLeft: "8px"
                                },
                            },
                            sx: {
                                justifyContent: 'center',
                            },
                        }}
                        InputLabelProps={{
                            sx: { textAlign: 'center', width: '100%' },
                        }}
                    />
                    {this.state.calendarOpen && (
                        <div className="reactCalendarBox">
                            <Calendar
                                selectRange
                                onChange={this.handleDateChange}
                                data-test-id="calendar"
                                value={this.state.tempDate || this.state.selectedDate}
                            />
                            <div className="action-button-div">
                                <Button
                                    className="action-btn cancel-btn"
                                    onClick={this.handleCalendarCancelBtn}
                                    data-test-id="cancel_calendar_btn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    data-test-id="calender_save_btn"
                                    className={this.handleCondition(this.state.tempDate, `action-btn save-btn active`, `action-btn save-btn`)}
                                    onClick={this.handleCalendarSaveBtn}
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    )}
                </CustomDatePicker>
            </Grid>
        )
    }

    searchTab = () => {
        return (
            <Grid item xs={12} md={6} xl={6} >
                <TextField
                    className="searchBox"
                    data-test-id="search_box"
                    value={this.state.searchText}
                    placeholder="Search"
                    onChange={this.handleSearch}
                    onKeyUp={this.handleKeyPress}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton data-test-id="search_icon" onClick={() => this.listCompetencyApiCall()}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        )
    }

    exportAllTab = () => {
        return (
            <Grid item xs={12} md={5} xl={5}>
                <Button className="compExportAllButton" data-test-id="export_all" onClick={this.handleExportAll}>
                    <Typography className="compExportAllText">
                        Export All
                    </Typography>
                </Button>
            </Grid>
        )
    }

    createCompBtn = () => {
        return (
            <Grid item xs={12} md={6} xl={6} >
                <Box className="createCompWrapper" data-test-id="close_dropdown" onBlur={this.closeCompDropdown}>
                    <Button
                        className="createCompButton"
                        data-test-id="create_competency"
                        onClick={this.toggleDropdown}
                    >
                        <Typography className="competencyText">
                            Create Competency
                        </Typography>
                        <img src={selectCompArrow.default} className="createCompArrow" />
                    </Button>
                    {this.state.createCompDropdown && (
                        <Box className="dropdownMenu">
                            <Typography
                                data-test-id="job_competency"
                                className="dropdownItem"
                                onMouseDown={(event) => {
                                    event.preventDefault();
                                    this.navigateToCreateComp();
                                }}
                            >
                                Job Competency
                            </Typography>
                            <Typography
                                data-test-id="competency_dictionary"
                                className="dropdownItem"
                                onMouseDown={(event) => {
                                    event.preventDefault();
                                    this.navigateToDictionary();
                                }}
                            >
                                Competency Dictionary
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Grid>
        )
    }

    competencyDataTable = () => {
        const id = "";
        return (
            <TableContainer sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell className="compTableTilteText" sx={{ paddingLeft: 0 }}>
                                <Box className="tableCellBox">
                                    Competency Name
                                    <img src={dropdownImg.default} />
                                </Box>
                            </TableCell>

                            <TableCell className="compTableTilteText">
                                <Box className="tableCellBox">
                                    Job Title
                                    <img src={dropdownImg.default} />
                                </Box>
                            </TableCell>
                            <TableCell className="compTableTilteText">
                                <Box className="tableCellBox">
                                    Job Family
                                    <img src={dropdownImg.default} />
                                </Box>
                            </TableCell>
                            <TableCell className="compTableTilteText">
                                <Box className="tableCellBox">
                                    Competency Type
                                    <img src={dropdownImg.default} />
                                </Box>
                            </TableCell>
                            <TableCell className="compTableTilteText">Target Level</TableCell>
                            <TableCell className="compTableTilteText">Behaviour</TableCell>
                            <TableCell className="compTableTilteText">Status</TableCell>
                            {this.state.selectedRowIds.length ?
                                <TableCell
                                    data-test-id="delete_selected"
                                    onClick={()=> this.openMultiDeletedialog(id)}
                                    className="compTableTilteText deleteSelectedStyle">
                                    Delete Selected
                                </TableCell> :
                                <TableCell
                                    data-test-id="delete_selected"
                                    className="compTableTilteText actionStyle">
                                    Action
                                </TableCell>}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.competencyData.data?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ padding: 0 }}>
                                    <Checkbox
                                        checked={this.state.selectedRowIds.includes(row.id)}
                                        onChange={(event) => this.handleCheckboxChange(event, row.id)}
                                        color="primary"
                                        data-test-id="delete_comp_checkbox"
                                        sx={{
                                            color: "rgb(148, 163, 184);",
                                            borderRadius: "16px",
                                            "&.Mui-checked": {
                                                color: "#64748B",
                                            },
                                            "& .MuiSvgIcon-root": {
                                                borderRadius: "16px"
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell className="compRowText" sx={{ width: "200px", paddingLeft: 0 }}>
                                    {row.attributes.competency_name}
                                </TableCell>
                                <TableCell className="compRowText" sx={{ width: "135px" }}>
                                    {row.attributes.job_title}
                                </TableCell>
                                <TableCell className="compRowText" sx={{ width: "135px" }}>
                                    {row.attributes.job_family}
                                </TableCell>

                                <TableCell className="compRowText" sx={{ width: "208px" }}>
                                    {row.attributes.competency_type}
                                </TableCell>

                                <TableCell className="compRowText" sx={{ width: "125px" }}>
                                    {row.attributes.target_level}
                                </TableCell>

                                <TableCell className="compRowText" sx={{ width: "195px" }}>
                                    {row.attributes.description}
                                </TableCell>

                                <TableCell className="compRowText" sx={{ width: "80px" }}>
                                    <Select
                                        displayEmpty
                                        variant="outlined"
                                        data-test-id="comp_status"
                                        className="compStatusRow"
                                        value={this.state.selectedStatus}
                                        renderValue={
                                            this.state.selectedStatus
                                                ? undefined
                                                : () => (
                                                    <div className="statusDropDown">
                                                        {row.attributes.status ? "Active" : "Inactive"}
                                                    </div>
                                                )
                                        }
                                        IconComponent={KeyboardArrowDownIcon}
                                        onChange={(event) => this.handleCompetencyStatus(event, row.id, row.attributes.status)}
                                        input={<InputBase />}
                                    >
                                        {this.state.competencyStatus.map((status) => (
                                            <MenuItem
                                                key={status}
                                                value={status}
                                                sx={{
                                                    background: `${status === "Active" ? "#f1f5f9" : "none"}`,
                                                    fontFamily: "Urbanist",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    margin: "4px",
                                                }}
                                            >
                                                {status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>

                                <TableCell sx={{ width: "145px", textAlign: "center" }}>
                                    <IconButton
                                        data-test-id="more_icon"
                                        onClick={(event) => this.handleMenuOpen(event, row.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        data-test-id="more_menu_close"
                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl) && this.state.selectedRowId === row.id}
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                borderRadius: "8px",
                                                border: "1px solid #E2E8F0",
                                            }
                                        }}
                                        sx={{
                                            mt: 4,
                                            ml: -3,
                                        }}
                                    >
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                            onClick={() => this.handleView(row.id)}
                                            data-test-id="view_competency"
                                        >
                                            View details
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#0F172A" }}
                                            onClick={() => this.handleEdit(row.id)}
                                            data-test-id="edit_competency"
                                        >
                                            Edit details
                                        </MenuItem>
                                        <MenuItem
                                            sx={{ padding: "8px 40px 8px 16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 400, color: "#F87171", borderRadius: "8px" }}
                                            onClick={()=> this.openMultiDeletedialog(row.id)}
                                            data-test-id="delete_competency"
                                        >
                                            Delete details
                                        </MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    competencyDashboard = () => {
        return (
            <Wrapper>
                <Card sx={{ borderRadius: 2, boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 144px 0px" }}>
                    <CardContent sx={{ paddingBottom: "0px !important" }}>
                        {this.state.competencyData?.data?.length ?
                            this.competencyDataTable() :
                            this.filterAndSearchNodata()
                        }
                    </CardContent>
                </Card>
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <DashboardHeader selectedMenu="Competencies" navigation={this.props.navigation} id={this.props.id}>
                <MainWrapper>
                    {!this.state.loader &&
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={11}>
                                <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={2}>
                                    <Grid item xs={11} sm={5} md={6}>
                                        {this.state.dataLoaded &&
                                            <Grid container spacing={2}>
                                                {this.selectDate()}
                                                {this.searchTab()}
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid item xs={11} sm={5} md={5.5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: {
                                                xs: "flex-start",
                                                md: "flex-end",
                                                xl: "flex-end"
                                            },
                                            marginRight: "16px"
                                        }}
                                    >
                                        {this.state.dataLoaded &&
                                            <Grid container spacing={2}>
                                                {this.exportAllTab()}
                                                {this.createCompBtn()}
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={11}>
                                <Grid container spacing={1} className="compMainHeaderGrid">

                                    <Grid item xs={12} sm={12} md={11.2} lg={11.5}>
                                        {this.state.dataLoaded ?
                                            this.competencyDashboard() :
                                            this.noDataScreen()
                                        }
                                    </Grid>

                                </Grid>
                                {this.multiDeleteCompetencyDialog()}
                                {this.state.dataLoaded && this.state.totalPage > 1 &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                                        <Pagination
                                            count={this.state.totalPage}
                                            page={this.state.page}
                                            data-test-id="page_change"
                                            onChange={(event, value) => this.handlePageChange(value)}
                                            color="primary"
                                        />
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    }
                    {this.state.loader && (
                        <Box className="loader-outer"
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box className="loader-inner">
                                <CircularProgress />
                            </Box>
                        </Box>
                    )}
                </MainWrapper>
            </DashboardHeader>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    width: "100%",
    "& .compTableTilteText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .compRowText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#044352"
    },
    "& .actionStyle": {
        fontSize: "16px",
        textAlign: "center"
    },

    "& .deleteSelectedStyle": {
        fontSize: "14px",
        color: "red",
        cursor: "pointer"
    },
    "& .cancelMultiDeleteButton": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        marginLeft: "16px",
        gap: "8px",
        opacity: "0px",
        background: "#F1F5F9",
        textTransform: "none",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .cancelMultiDeleteArabic": {
        borderRadius: "8px",
        height: "56px",
        marginRight: "16px",
        gap: "8px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        background: "#F1F5F9",
        textTransform: "none",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        width: "120px",
        color: "#000",
        "&:hover": {
            backgroundColor: "#F1F5F9"
        },
    },
    "& .compStatusRow": {
        textTransform: "none",
        width: "100px",
        height: "30px",
        borderRadius: "4px",
        padding: "0px 20px 0px 16px",
        border: "1px solid #CBD5E1",
        color: "#64748B",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "3px"
    },
    "& .multiDeleteButtonsBox": {
        margin: "16px 0px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .multiDeleteButtonsArabic": {
        paddingLeft: "16px",
        margin: "16px 0px",
        display: "flex",
        justifyContent: "flex-end"
    },
    "& .MuiSelect-icon": {
        transform: 'none !important',
    },
    "& .multiDeleteDialogClose": {
        position: 'absolute',
        color: "#334155",
        top: 16
    },
    "& .statusDropDown": {
        color: "#64748B",
        fontFamily: "Urbanist",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "3px"
    },
    "& .multiDeleteImageBox": {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "center"
    },
    "& .deleteCompDialogTitle": {
        color: "#1E1E1E",
        fontFamily: "Urbanist",
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        margin: "8px 16px"
    },
    "& .multiDeleteCompButton": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        height: "56px",
        padding: "16px 24px",
        fontWeight: 700,
        width: "30%",
        borderRadius: "8px",
        gap: "8px",
        opacity: "0px",
        marginLeft: "16px",
        background: "#044352",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
    "& .multiDeleteButtonArabic": {
        borderRadius: "8px",
        opacity: "0px",
        fontSize: "16px",
        fontWeight: 700,
        height: "56px",
        gap: "8px",
        fontFamily: "Urbanist",
        padding: "16px 39.5px 16px 36.5px",
        marginRight: "16px",
        background: "#044352",
        textTransform: "none",
        width: "120px",
        "&:hover": {
            backgroundColor: "#044352"
        },
    },
})
const CustomDatePicker = styled(Box)({
    borderRadius: '8px',
    position: 'relative' as 'relative',
    boxSizing: "border-box",
    padding: 0,
    border: "1px solid #94A3B8",
    "&:hover": {
        border: "1px solid #94A3B8"
    },
    "& .MuiInput-underline::after, .MuiInput-underline::before": {
        display: 'none'
    },
    "& .reactCalendarBox": {
        marginTop: "5px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        position: 'absolute',
        background: "white",
        top: '100%',
        left: -10,
        zIndex: 1000,
    },
    "& .react-calendar": {
        border: "none",
        fontFamily: "Urbanist",
    },
    "& .react-calendar__navigation button.react-calendar__navigation__label": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#0F172A",
    },
    "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button": {
        display: "none",
    },
    "& .react-calendar__navigation button": {
        fontSize: '14px',
        fontFamily: "Urbanist",
        fontWeight: 700,
        color: "#94A3B8",
    },
    "& .react-calendar__tile.react-calendar__tile--now": {
        background: 'none',
        color: '#0F172A',
    },
    "& .react-calendar__month-view__weekdays__weekday": {
        textTransform: 'capitalize',
        fontSize: '14px',
        color: '#64748B',
        fontWeight: 400,
        textDecoration: 'none',
    },
    "& .react-calendar__month-view__weekdays__weekday abbr": {
        textDecoration: 'none',
    },
    "& .react-calendar__tile": {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        width: '50px',
        height: '50px',
    },
    "& .react-calendar__tile.react-calendar__tile--range": {
        background: '#F1F5F9',
        color: '#044352',
        borderRadius: '0px',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeStart": {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        background: '#F1F5F9',
        color: '#044352',
    },
    "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
        background: '#F1F5F9',
        color: '#044352',
        fontSize: '14px',
        fontWeight: 700,
    },
    "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
        background: '#F1F5F9',
        color: '#044352',
        pointerEvents: "none",
    },
    "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
        color: '#94A3B8'
    },
    "& .action-button-div": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 15px',
        gap: '20px'
    },
    "& .action-button-div button": {
        width: '140px',
        height: '50px',
        borderRadius: '7.21px',
        fontFamily: "Urbanist",
        textTransform: 'capitalize',
        fontSize: '14.42px',
    },
    "& .action-button-div .cancel-btn": {
        background: '#F1F5F9',
        color: "#044352",
        boxShadow: "0px 3.6px 3.6px 0px #00000040"
    },
    "& .action-button-div .save-btn.active": {
        background: '#044352',
        color: '#FFFFFF'
    },
    "& .action-button-div .save-btn": {
        background: '#044352',
        color: '#FFFFFF'
    },
});

export const EmptyWrapper = styled(Box)({
    width: "100%",
    paddingBottom: "30px",
    height: "calc(100vh - 180px)",
    "& .createCompetencyButton": {
        marginTop: "24px",
        padding: "8px 90px",
        height: "58px",
        maxWidth: "400px",
        borderRadius: "4px",
        background: "#044352",
    },
    "& .createCompDictionaryButton": {
        marginTop: "24px",
        padding: "8px 50px",
        maxWidth: "400px",
        height: "58px",
        borderRadius: "4px",
        border: "1px solid #94A3B8"
    },
    "& .compNoDataImageBox": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .compNoDataNextText": {
        lineHeight: "26px",
        color: "#8A96A8",
        width: "50%",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "18px",
        textAlign: "center"
    },
    "& .noDataNextText": {
        lineHeight: "26px",
        color: "#8A96A8",
        width: "50%",
        fontWeight: 400,
        fontFamily: "Urbanist",
        fontSize: "18px",
        textAlign: "center"
    },
    "& .gridContainer": {
        height: "100%"
    },
    "& .compDectionaryText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#000104",
        textTransform: "none"
    },
    "& .compNoDataMainText": {
        fontFamily: "Urbanist",
        color: "#000104",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "40px",
    },
    "& .compCreateText": {
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "none"
    }
})

export const MainWrapper = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "42px",
    paddingBottom: "30px",
    "& .backArrowImg": {
        paddingRight: "12px"
    },
    "& .createCompWrapper": {
        height: "auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        position: "relative",
        "@media(max-width:799px)": {
            flexWrap: "wrap"
        },
    },
    "& .dropdownMenu": {
        display: "flex",
        flexDirection: "column",
        background: "white",
        border: "1px solid #ccc",
        position: "absolute",
        zIndex: 1000,
        borderRadius: "8px",
        width: "100%",
        top: "60px",
    },
    "& .createCompArrow": {
        margin: "3px 0px 0px 12px"
    },
    "& .tableCellBox": {
        display: "flex",
        alignItems: "center"
    },
    "& .dropdownItem:hover": {
        padding: "8px",
        margin: "2px",
        backgroundColor: "#E2E8F0",
        borderRadius: "8px",
        paddingLeft: "16px"
    },
    "& .dropdownItem": {
        padding: "8px",
        margin: "2px",
        cursor: "pointer",
        fontFamily: "Urbanist",
        fontSize: "14px",
        paddingLeft: "16px"
    },
    "& .compTitle": {
        color: "#000104",
        marginLeft: "12px",
        fontWeight: 600,
        fontFamily: "Urbanist",
        fontSize: "25px",
        lineHeight: "30px"
    },
    "& .compMainHeaderGrid": {
        marginTop: "20px"
    },
    "& .compExportAllButton": {
        borderRadius: "4px",
        width: "100%",
        height: "58px",
        border: "2px solid #94A3B8",
        "&:hover": {
            background: "none"
        }
    },
    "& .compExportAllText": {
        color: "#64748B",
        fontWeight: 700,
        fontFamily: "Urbanist",
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "none",
    },
    "& .createCompButton": {
        height: "58px",
        width: "100%",
        background: "#044352",
        borderRadius: "4px",
        padding: "16px 30px",
        position: "relative",
    },
    "& .competencyText": {
        lineHeight: "24px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 700,
        color: "#FFFFFF",
        textTransform: "none"
    },
    "& .searchBox": {
        border: "1px solid #94A3B8",
        width: "100%",
        height: "54px",
        borderRadius: "4px",
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#6C7B89",
        "&:hover": {
            borderColor: "none"
        },
    },

})
// Customizable Area End