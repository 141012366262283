export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const menuBgImg = require("../assets/menuBgImg.png");
export const arrow_Left = require("../assets/arrow_Left.svg");
export const profile = require("../assets/profile.svg");
export const notification = require("../assets/notification.svg");
export const language = require("../assets/language.svg");
export const roles = require("../assets/roles.svg");
export const talentProfile = require("../assets/talentProfile.svg");
export const help = require("../assets/help.svg");
export const userDefaultImage = require("../assets/userDefaultImage.png");
export const logout = require("../assets/logout.svg");
export const deleteIcon = require("../assets/deleteIcon.svg");
export const right = require("../assets/rightIcon.png");
export const defaultIcon = require("../assets/defalut.png");
export const chnageRole = require("../assets/chnageRole.png");

